<template>
  <SkeletonComponentLoader>
    <SkeletonBaseForeground
      v-if="!isCriticalLoaded"
      :width="methodsSize.width"
      :height="methodsSize.height"
      :border-radius="24"
    />
    <PaymentRightSideController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { useCountriesStore } from '~/features/payment/store/countries.store';

const countriesStore = useCountriesStore();
const { isLoaded: countriesLoaded } = storeToRefs(countriesStore);

const bonusesStore = useBonusesStore();
const { isLoaded: bonusesLoaded } = storeToRefs(bonusesStore);

const changeXXL = GlobalUtils.Media.changeByMedia('xxl');
const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const isCriticalLoaded = computed(() => countriesLoaded.value && bonusesLoaded.value);

const methodsSize = computed(() => ({
  height: changeXL(604, changeMD(574, 422)),
  width: changeXXL(608, changeXL(504, changeMD(304, 312))),
}));
</script>
