<template>
  <SkeletonComponentLoader>
    <SkeletonBaseForeground
      v-if="!isCriticalLoaded"
      :width="titleSize.width"
      :height="titleSize.height"
      :border-radius="6"
    />
    <PaymentTitleController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';

const bonusesStore = useBonusesStore();
const { isLoaded: bonusesLoaded } = storeToRefs(bonusesStore);

const countriesStore = useCountriesStore();
const { isLoaded: countriesLoaded } = storeToRefs(countriesStore);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const isCriticalLoaded = computed(() => countriesLoaded.value && bonusesLoaded.value);

const titleSize = computed(() => ({
  height: changeXL(24, changeMD(20, 16)),
  width: changeXL(490, changeMD(372, 312)),
}));
</script>
