export enum ESliderAnimation {
  CLICK = 'click',
  POINTER = 'pointer',
}

export const SLIDER_PC_GAP = 8;

export const arrowsConfig = {
  size: 'm',
  theme: 'primary',
} as const;
