<template>
  <section class="methods-controller">
    <LazyPaymentMethodButton
      v-for="method in methods"
      :key="method.id"
      :icon="method.icon"
      :is-active="isActiveMethod(method.id)"
      @click="handleSelectMethod(method)"
    />
  </section>
</template>

<script setup lang="ts">
import { useMethodsStore } from '~/features/payment/store/methods.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { ECountryMethodType, type ICountryMethod } from '~/features/payment/types/country/client.types';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';

const changeMD = GlobalUtils.Media.changeByMedia('md');

const offersStore = useOffersStore();
const { selectedOffer } = storeToRefs(offersStore);

const methodsStore = useMethodsStore();
const { methods: allMethods, selectedMethod } = storeToRefs(methodsStore);

const methods = computed(() =>
  selectedOffer.value
    ? allMethods.value.filter(
        // При выбранном оффере фильтруем по типу метода и минимальной цене оффера
        (method) =>
          method.type === ECountryMethodType.COMMON &&
          selectedOffer.value &&
          selectedOffer.value.price.new >= method.min,
      )
    : allMethods.value,
);

const sumStore = useSumStore();
const { sumWithRate } = storeToRefs(sumStore);

const isActiveMethod = (id: number) => selectedMethod.value?.id === id;

const handleSelectMethod = (method: ICountryMethod) => {
  methodsStore.selectMethod(method);

  if (!window || method.type === ECountryMethodType.SKIN || sumWithRate.value >= method.min || changeMD(true, false))
    return;
  window.scrollTo({ behavior: 'smooth', top: 0 });
};
</script>

<style scoped lang="scss" src="./MethodsController.scss" />
