<script setup lang="ts">
import type { BaseArrowButtonProps } from './BaseArrowButton.types';

const props = withDefaults(defineProps<BaseArrowButtonProps>(), {
  theme: 'primary',
});

const rootClasses = computed(() => ['arrow-button', `arrow-button--${props.theme}`, `arrow-button--${props.size}`]);

const iconClasses = computed(() => [
  'arrow-button__icon',
  { 'arrow-button__icon--reverse': props.direction === 'right' },
]);
</script>

<template>
  <button :class="rootClasses">
    <SvgoArrowLeftIcon :class="iconClasses" />
  </button>
</template>

<style scoped lang="scss" src="./BaseArrowButton.scss" />
