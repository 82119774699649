<template>
  <section class="pay-block">
    <div v-if="!isInstant" class="pay-block__checkmark">
      <SharedCheckbox
        :model-value="isChecked"
        active-background-color="var(--main-color-100)"
        background-color="var(--main-color-100)"
        @update:model-value="$emit('update:isChecked', $event)"
      />
      <span class="pay-block__checkmark-text" @click="$emit('update:isChecked', !isChecked)">
        {{ $t('PaymentPage.iAccept') }}
        <NuxtLinkLocale
          :to="useLinks().AGREEMENT"
          class="pay-block__checkmark-text--link"
          :href="useLinks().AGREEMENT"
          target="_blank"
        >
          {{ $t('PaymentPage.userAgreement') }}
        </NuxtLinkLocale>
      </span>
    </div>
    <SharedKitButton
      :is-disabled="isButtonDisabled"
      :is-loading="isLoading"
      :color-preset="buttonColor"
      :size-preset="SizePresets.L"
      :type-preset="TypePresets.ROUND"
      :text="buttonText"
      @click="handleStartClick"
    >
      <template #append>
        <span v-if="showSum" :class="{ 'pay-block__sum': !hasBan }">{{ sumWithCurrency }}</span>
      </template>
    </SharedKitButton>
    <span class="pay-block__address">
      {{ beneficiary }}. {{ address }}
      <p v-if="hasBan">{{ $t('PaymentPage.bans') }}</p>
    </span>
  </section>
</template>

<script setup lang="ts">
import { type IPayBlockProps, type IPayBlockEmits, EPayBlockPayTypes, EPayBlockTypes } from './PayBlock.types';
import { PaymentButtonPresets } from '~/features/payment/presets/SharedKitButton.presets';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const {
  project,
  legalData: { beneficiary, address },
} = useProjectSettings();

const props = defineProps<IPayBlockProps>();
const emits = defineEmits<IPayBlockEmits>();

const { t } = useI18n();

const savedPromo = usePersistedRef('payment-promo-input', '');

const hasBan = computed(() => {
  return project === 'dota-global' || project === 'cs-global';
});

const isButtonDisabled = computed(() => {
  if (props.isInstant) return false;
  return !props.isChecked || !props.isValidFields;
});

const buttonColor = computed(() => {
  if (props.type === EPayBlockTypes.BLOGGER) return PaymentButtonPresets.PAY_BLOGGER;
  return ColorPresets.PRIMARY;
});

const buttonText = computed(() => {
  if (props.payType === EPayBlockPayTypes.CASH) return t('PaymentPage.pay');
  if (props.payType === EPayBlockPayTypes.SKINS) return t('PaymentPage.paySkin');

  return t('PaymentPage.activate');
});

const showSum = computed(() => props.payType === EPayBlockPayTypes.CASH && props.sum);
const sumWithCurrency = computed(() => (props.sum ? `${props.sum} ${props.currencySymbol}` : ''));

const handleStartClick = () => {
  savedPromo.value = '';
  emits('start');
};
</script>

<style scoped lang="scss" src="./PayBlock.scss" />
