import { storeToRefs } from 'pinia';

import { useCountryStore } from './country.store';

export const useSumPresetStore = defineStore('payment/sum-preset', () => {
  /* -- Stores -- */
  /* Стор стран */
  const countryStore = useCountryStore();
  const { country } = storeToRefs(countryStore);

  /* -- Getters -- */
  /* Кнопки доп.суммы выбранной страны */
  const presets = computed(
    () =>
      country.value?.buttons ?? {
        desktop: [],
        mobile: [],
      },
  );

  return {
    presets,
  };
});
