<template>
  <div :class="['field-with-select', rootClasses]">
    <div class="field-with-select__content">
      <div class="field-with-select__content-input">
        <input
          ref="input"
          type="text"
          class="field-with-select__content-input__field"
          :placeholder="placeholder"
          :value="value"
          :disabled="isDisabledInput"
          @input="handleInput"
          @blur="$emit('inputBlur')"
        />
        <div ref="sizer" class="field-with-select__content-input__sizer">{{ value }}</div>
        <span class="field-with-select__content-input__symbol">{{ currencySymbol }}</span>
      </div>
      <div class="field-with-select__content-select">
        <PaymentSelectField
          v-if="country"
          :options="country.options"
          :value="country.value"
          @update:value="$emit('update:countryValue', $event)"
        />
      </div>
    </div>
    <div v-if="error" class="field-with-select__error">{{ error }}</div>
  </div>
</template>

<script setup lang="ts">
import type { IFieldWithSelectProps, IFieldWithSelectEmits } from './FieldWithSelect.types';

const props = defineProps<IFieldWithSelectProps>();
const emits = defineEmits<IFieldWithSelectEmits>();

const input = ref<HTMLElement>();
const sizer = ref<HTMLElement>();

const rootClasses = computed(() => ({
  'field-with-select--disabled': props.isDisabled,
  'field-with-select--error': props.error,
}));

const currencyOffset = computed(() => {
  const basePadding = 16; // Отсуп слева
  const baseGap = 8; // Отступ между валютой и полем
  const baseOffset = 2; // Базовый отступ

  const inputCount = props.value?.length ?? 0;
  if (!inputCount) return GlobalUtils.Converting.toPx(basePadding + baseGap + baseOffset * 6);

  const offsetWidth = sizer.value?.offsetWidth ?? 0;
  const inputWidth = input.value?.offsetWidth ?? 0;

  const inputOffset = inputWidth - basePadding - baseOffset;
  const offset = basePadding + baseGap + baseOffset + offsetWidth;

  const result = offset > inputOffset ? inputOffset : offset;

  return GlobalUtils.Converting.toPx(result);
});

const handleInput = (e: Event) => {
  if (!e.target) return;

  const target = e.target as HTMLInputElement;
  target.value = target.value.replace(/\D/g, '').slice(0, 9);

  emits('update:value', target.value);
};
</script>

<style scoped lang="scss">
.field-with-select {
  --currency-offset: v-bind('currencyOffset');
}
</style>

<style scoped lang="scss" src="./FieldWithSelect.scss" />
