<template>
  <section class="additional-fields">
    <header class="additional-fields__header">
      <span class="additional-fields__header-title">{{ $t('PaymentPage.additionalFields') }}</span>
      <span class="additional-fields__header-description">
        {{ $t('PaymentPage.additionalFieldsText') }}
      </span>
    </header>
    <div class="additional-fields__content">
      <PaymentSelectField
        v-if="shownFields[ECountryMethodField.DOCUMENT_NUMBER] && fields[ECountryMethodField.DOCUMENT_TYPE]"
        v-model:value="selectedDocument"
        :error="fields[ECountryMethodField.DOCUMENT_TYPE].error"
        :label="$t('PaymentPage.document')"
        :options="shownFields[ECountryMethodField.DOCUMENT_NUMBER].options ?? []"
        :placeholder="$t('PaymentPage.selectDocument')"
        :required="shownFields[ECountryMethodField.DOCUMENT_NUMBER].required"
      />
      <SharedInputField
        v-if="shownFields[ECountryMethodField.DOCUMENT_NUMBER] && fields[ECountryMethodField.DOCUMENT_NUMBER]"
        v-model:value="fields[ECountryMethodField.DOCUMENT_NUMBER].value"
        :error="fields[ECountryMethodField.DOCUMENT_NUMBER].error"
        :is-disabled="!fields[ECountryMethodField.DOCUMENT_TYPE].value"
        :label="shownFields[ECountryMethodField.DOCUMENT_NUMBER].label"
        placeholder="____-____"
        :required="shownFields[ECountryMethodField.DOCUMENT_NUMBER].required"
      />
      <SharedInputField
        v-if="shownFields[ECountryMethodField.PHONE] && fields[ECountryMethodField.PHONE]"
        v-model:value="fields[ECountryMethodField.PHONE].value"
        :error="fields[ECountryMethodField.PHONE].error"
        :label="shownFields[ECountryMethodField.PHONE].label"
        placeholder="+7 ___-__-__"
        :required="shownFields[ECountryMethodField.PHONE].required"
      />
      <SharedInputField
        v-if="shownFields[ECountryMethodField.EMAIL] && fields[ECountryMethodField.EMAIL]"
        v-model:value="fields[ECountryMethodField.EMAIL].value"
        :error="fields[ECountryMethodField.EMAIL].error"
        :label="shownFields[ECountryMethodField.EMAIL].label"
        placeholder="Email@gmail.com"
        :required="shownFields[ECountryMethodField.EMAIL].required"
      />
      <SharedInputField
        v-if="shownFields[ECountryMethodField.FIRST_NAME] && fields[ECountryMethodField.FIRST_NAME]"
        v-model:value="fields[ECountryMethodField.FIRST_NAME].value"
        :error="fields[ECountryMethodField.FIRST_NAME].error"
        :label="shownFields[ECountryMethodField.FIRST_NAME].label"
        :placeholder="$t('PaymentPage.placeholderName')"
        :required="shownFields[ECountryMethodField.FIRST_NAME].required"
      />
      <SharedInputField
        v-if="shownFields[ECountryMethodField.LAST_NAME] && fields[ECountryMethodField.LAST_NAME]"
        v-model:value="fields[ECountryMethodField.LAST_NAME].value"
        :error="fields[ECountryMethodField.LAST_NAME].error"
        :label="shownFields[ECountryMethodField.LAST_NAME].label"
        :placeholder="$t('PaymentPage.placeholderSecondName')"
        :required="shownFields[ECountryMethodField.LAST_NAME].required"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ECountryMethodField } from '~/features/payment/types/country/client.types';
import { useAdditionalFieldsStore } from '~/features/payment/store/additionalFields.store';

const additionalFieldsStore = useAdditionalFieldsStore();
const { fields, selectedDocument, shownFields } = storeToRefs(additionalFieldsStore);
</script>

<style scoped lang="scss" src="./AdditionalFieldsController.scss" />
