<template>
  <SkeletonComponentLoader>
    <div v-if="!isAllLoaded" class="methods-container">
      <SkeletonBaseForeground :width="methodsSize.width" :height="methodsSize.height" :border-radius="24" />
      <SkeletonBaseForeground v-if="changeMD(false, true)" :width="312" :height="50" :border-radius="80" />
    </div>
    <PaymentLeftSideController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useCountryStore } from '~/features/payment/store/country.store';
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';

const countryStore = useCountryStore();
const { isLoaded: countryLoaded } = storeToRefs(countryStore);

const bonusesStore = useBonusesStore();
const { isLoaded: bonusesLoaded } = storeToRefs(bonusesStore);

const countriesStore = useCountriesStore();
const { isLoaded: countriesLoaded } = storeToRefs(countriesStore);

const changeXXL = GlobalUtils.Media.changeByMedia('xxl');
const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const isAllLoaded = computed(() => countriesLoaded.value && bonusesLoaded.value && countryLoaded.value);

const methodsSize = computed(() => ({
  height: changeXL(288, 376),
  width: changeXXL(580, changeXL(484, changeMD(296, 312))),
}));
</script>

<style scoped lang="scss" src="./LeftSideController.skeleton.scss" />
