<template>
  <section class="skins-controller">
    <SharedInputField
      v-model:value="promoInfo.input"
      class="skins-controller__promo"
      :placeholder="$t('PaymentPage.promocode')"
      :is-succeed="promoInfo.isSucceed"
      :error="promoInfo.error"
    />
    <span class="skins-controller__info">
      {{ $t('PaymentPage.skinsInfo') }}
    </span>
    <div class="skins-controller__content">
      <div v-if="!skinsBloggerBonuses" class="skins-controller__content-bonuses">
        <PaymentBonusCard v-if="commonPromo" :amount="commonPromo" />
        <LazyPaymentSkinsBonusCard
          v-for="bonus in skinsAvailableBonuses"
          :key="bonus.percent"
          :amount="bonus.percent"
          :from="bonus.from"
        />
      </div>
      <div v-else class="skins-controller__content-blogger">
        <div class="skins-controller__content-blogger__buttons">
          <Transition name="fade" mode="out-in">
            <div
              v-show="isLeftArrowShown"
              class="skins-controller__content-blogger__buttons--left"
              @click="handleArrowClick(-1)"
            >
              <SvgoArrowLeftIcon />
            </div>
          </Transition>
          <Transition name="fade" mode="out-in">
            <div
              v-show="isRightArrowShown"
              class="skins-controller__content-blogger__buttons--right"
              @click="handleArrowClick(1)"
            >
              <SvgoArrowRightIcon />
            </div>
          </Transition>
        </div>
        <div ref="skinsBloggerRewardSlider" class="skins-controller__content-blogger__slider">
          <div
            v-for="reward in skinsBloggerBonuses"
            :key="reward.level"
            class="skins-controller__content-blogger__slider--item"
          >
            <LazyPaymentSkinsBloggerResult
              :level="reward.level"
              :from="reward.bonus.from"
              :coin-icon="coinIcon"
              :percent="reward.bonus.percent"
              :coins="reward.bonus.coins"
              :case-data="reward.bonus.case"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useBonusesStore } from '~/features/payment/store/bonuses.store';
import { usePromoStore } from '~/features/payment/store/promo.store';

import { useEventStore } from '~/store/event/event.store';
import { useDragScroll } from '~/features/payment/composables/useDragScroll';

const skinsBloggerRewardSlider = ref<HTMLElement>();
useDragScroll(skinsBloggerRewardSlider);

const promoStore = usePromoStore();
const { promoInfo, commonPromo, skinsBloggerBonuses } = storeToRefs(promoStore);

const bonusesStore = useBonusesStore();
const { skinsAvailableBonuses } = storeToRefs(bonusesStore);

const eventStore = useEventStore();
const { coinIcon } = storeToRefs(eventStore);

const isLeftArrowShown = ref(false);
const isRightArrowShown = ref(false);

const handleArrowClick = (direction: 1 | -1) => {
  if (!skinsBloggerRewardSlider.value) return;

  const halfWidth = skinsBloggerRewardSlider.value.clientWidth / 2;
  skinsBloggerRewardSlider.value.scrollBy({
    behavior: 'smooth',
    left: direction * halfWidth,
  });
};

const detectScrollBoundaries = () => {
  if (!skinsBloggerRewardSlider.value) return;
  const { scrollLeft, scrollWidth, clientWidth } = skinsBloggerRewardSlider.value;

  isLeftArrowShown.value = scrollLeft > 0;
  isRightArrowShown.value = scrollLeft < scrollWidth - clientWidth;
};

onMounted(() => {
  if (!skinsBloggerRewardSlider.value || !skinsBloggerBonuses.value) return;

  skinsBloggerRewardSlider.value.addEventListener('scroll', detectScrollBoundaries, true);
  isRightArrowShown.value = skinsBloggerBonuses.value.length > 2;
});

onBeforeUnmount(() => {
  if (!skinsBloggerRewardSlider.value) return;
  skinsBloggerRewardSlider.value.removeEventListener('scroll', detectScrollBoundaries, true);
});
</script>

<style scoped lang="scss" src="./SkinsController.scss" />
