<template>
  <section v-if="isLoaded" class="mini-offers">
    <div class="mini-offers__controls">
      <BaseArrowButton
        v-if="isShowPrevIcon"
        class="mini-offers__controls-prev"
        direction="left"
        v-bind="arrowsConfig"
        @click="handlePrevArrow"
      />
      <BaseArrowButton
        v-if="isShowNextIcon"
        class="mini-offers__controls-next"
        direction="right"
        v-bind="arrowsConfig"
        @click="handleNextArrow"
      />
    </div>
    <div class="mini-offers__container">
      <div ref="listRef" class="mini-offers__content" :style="listStyles" @pointerdown.passive="handleStart">
        <SecretShopMiniOffer
          v-for="[id, offer] in shownOffers"
          :key="id"
          class="mini-offers__content-item"
          v-bind="offer"
          :is-selected="id === selectedOffer?.id"
          @select="handleSelect(offer)"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { arrowsConfig, ESliderAnimation, SLIDER_PC_GAP } from './MiniOffersController.data';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';
import type { IOffer } from '~/features/secret-shop/types/offers/client.types';
import { PaymentEvents } from '~/repository/amplitude/events/payment';

const offersStore = useOffersStore();
const { offers, isLoaded, selectedOffer } = storeToRefs(offersStore);

const shownOffers = computed(() => [
  ...offers.value.personal,
  ...offers.value.event,
  ...offers.value.limited,
  ...offers.value.classic,
]);

const listRef = ref<HTMLElement>();

const handleSelect = (offer: IOffer) => {
  PaymentEvents.offerClicked({
    'Offer Price': offer.price.new,
    'Offer Name': offer.name,
  });

  selectedOffer.value = offer;
};

const sliderData = ref({
  animationMode: ESliderAnimation.POINTER,
  maxDistance: 0,
  startX: 0,
  oldX: 0,
  currentX: 0,
});

const listStyles = computed(() => ({
  '--list-offset': `${sliderData.value.currentX}px`,
  '--list-animation': `var(--${sliderData.value.animationMode}-animation)`,
}));

const handleStart = (e: PointerEvent) => {
  const maxDistance = getMaxDistance();
  if (!maxDistance) return;

  sliderData.value.animationMode = ESliderAnimation.POINTER;
  sliderData.value.maxDistance = maxDistance;
  sliderData.value.startX = e.x;

  document.addEventListener('pointermove', handleMove, { passive: true });
  document.addEventListener('pointerup', handleEnd, { passive: true });
  document.addEventListener('pointercancel', handleEnd, { passive: true });
};

const handleMove = (e: PointerEvent) => {
  e.stopImmediatePropagation();

  const { maxDistance, startX, oldX } = sliderData.value;
  const distance = e.x - startX + oldX;

  sliderData.value.currentX = Math.min(0, Math.max(distance, maxDistance));
};

const handleEnd = (e: PointerEvent) => {
  e.stopImmediatePropagation();

  sliderData.value.oldX = sliderData.value.currentX;

  document.removeEventListener('pointermove', handleMove);
  document.removeEventListener('pointerup', handleEnd);
  document.removeEventListener('pointercancel', handleEnd);
};

const _getScrollPrevDistance = () => {
  const slides = [...document.querySelectorAll('.mini-offers__content-item')] as HTMLElement[];
  const listWidth = listRef.value?.parentElement?.offsetWidth ?? 0;

  let totalWidth = -sliderData.value.currentX;
  let distance = 0;
  let items = 0;

  for (let i = slides.length - 1; i >= 0; i--) {
    const slide = slides[i];
    const offsetWidth = slide.offsetWidth + SLIDER_PC_GAP;

    totalWidth -= offsetWidth;
    if (totalWidth >= listWidth) continue;

    distance += offsetWidth;
    items++;

    if (items === 3) break;
  }

  return distance;
};

const _getScrollNextDistance = () => {
  const slides = [...document.querySelectorAll('.mini-offers__content-item')] as HTMLElement[];
  const listWidth = listRef.value?.parentElement?.offsetWidth ?? 0;

  let totalWidth = sliderData.value.currentX;
  let distance = 0;
  let items = 0;

  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i];
    const offsetWidth = slide.offsetWidth + SLIDER_PC_GAP;

    totalWidth += offsetWidth;
    if (totalWidth <= listWidth) continue;

    distance += offsetWidth;
    items++;

    if (items === 3) break;
  }

  return distance;
};

const handlePrevArrow = () => {
  sliderData.value.animationMode = ESliderAnimation.CLICK;

  const distance = _getScrollPrevDistance();
  const newPosition = Math.min(sliderData.value.currentX + distance, 0);

  sliderData.value.currentX = newPosition;
  sliderData.value.oldX = newPosition;
};

const handleNextArrow = () => {
  sliderData.value.animationMode = ESliderAnimation.CLICK;

  const maxDistance = getMaxDistance();
  if (!maxDistance) return;

  sliderData.value.maxDistance = maxDistance;

  const distance = _getScrollNextDistance();
  const newPosition = Math.max(sliderData.value.currentX - distance, maxDistance);
  sliderData.value.currentX = newPosition;
  sliderData.value.oldX = newPosition;
};

const getMaxDistance = () => {
  if (!listRef.value) return;

  const { width: listWidth } = listRef.value.getBoundingClientRect();
  const { width: rootWidth } = listRef.value.parentElement?.getBoundingClientRect() ?? { width: 0 };

  return -listWidth + rootWidth;
};

const isShowPrevIcon = computed(() => sliderData.value.currentX < 0);

const isShowNextIcon = computed(
  () => sliderData.value.currentX > sliderData.value.maxDistance || sliderData.value.maxDistance === 0,
);
</script>

<style scoped lang="scss" src="./MiniOffersController.scss"></style>
